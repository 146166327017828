import React from 'react'
import { Container, Image, Nav, Navbar } from 'react-bootstrap'
import { Link } from 'react-scroll';
import logo from 'assets/PestInsightsLogo_rectangle.png';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
const Footer = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const menuItems = [
        { path: 'about', label: 'About' },
        { path: 'features', label: 'Features' },

        { path: 'testimonials', label: 'Testimonials' },
    ];
    return (
        <footer>

            <Navbar className="p-0 py-2 border-bottom ">
                <Container>
                    <Navbar.Brand as={NavLink} className='d-md-block d-none p-0' to="/" style={{ height: "65px" }}>
                        {/* <Image src={logo} alt="Pest Insights" fluid /> */}
                        <Image src={logo} alt="Pest Insights" fluid style={{ height: '65px' }} />
                    </Navbar.Brand>


                   <Nav className="mx-auto gap-lg-3">
                        {menuItems.map((tab, index) => (
                            <React.Fragment key={tab?.label}>
                                <Link

                                    to={tab?.path}
                                    // smooth
                                    className="nav-link pointer fw-semibold"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        if (location.pathname !== '/') {
                                            navigate('/', { state: { targetSection: tab?.path } });
                                        }
                                    }}
                                >
                                    {tab?.label}
                                </Link>
                                {index < menuItems?.length - 1 && <div className="vr mx-2 my-2 fw-bold"></div>}
                            </React.Fragment>
                        ))}
                    </Nav>
                  <div style={{width:"216px"}}></div>
                               </Container>
            </Navbar>
            <div className="py-1 text-center text-muted">
                <Container>
                    <p className='mb-0 fw-semibold'> @All Rights Reserved</p>
                </Container>
            </div>
        </footer >
    )
}

export default Footer
