import Layout from 'components/layouts/landing/Layout';
import { Route, Routes } from 'react-router-dom';
import Landing from 'views/Landing';
import NotFound from 'views/NotFound';
import SignUp from 'views/SignUp';

const Navigation = () => {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='/*' element={<NotFound />} />
                <Route path='/' element={<Landing />} />
                <Route path='/waiting-form' element={<SignUp />} />

            </Route>
        </Routes>
    );
};

export default Navigation;
