import "bootstrap/dist/css/bootstrap.min.css";
import Navigation from "Navigation";
import "react-country-state-city/dist/react-country-state-city.css";
import ReactDOM from "react-dom/client";
import 'react-loading-skeleton/dist/skeleton.css';
import { BrowserRouter } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import "react-toggle/style.css";
import "rsuite/dist/rsuite.min.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "./global.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Navigation />
  </BrowserRouter>
);
